@import url(style/header&footer.css);
@import url(style/images.css);
@import url(style/forms.css);
@import url(style/aboutMe.css);
@import url(style/text.css);

html{
  padding: 0;
}

body{
  font-family: "Smooch Sans", sans-serif;
  font-weight: normal;
  background-color: hsl(120, 11%, 44%);
  margin: 0;
}

main{
  text-align: center;
  background-color: white;
  font-size: 110%;
}

.mainWrapper, footer, header{
  box-shadow: -15px 0 15px -15px , 15px 0 15px -15px;
}

svg{
  fill: hsl(0, 0%, 100%);
}

.foreword{
  width: 80%;
  margin: 0 auto;
  padding: 1em 0;
}

.legalNotice, .dataPrivacyPolicy{
  margin: 0 1.5em;
  text-align: start;
}

@media only screen and (min-width: 1080px) {
  main{
    padding: 0 10%;
  }
}
