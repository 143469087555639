.contactPage{
    padding: 0.5em 1.5em;
    margin: auto;
}
  
.contactAdress{
    text-align: left;
    padding: 0;
}

.mail{
    fill: black;
    margin: 0.4em 0.3em 0 0;
}

.phone{
    fill: black;
    margin: 0 0.5em 0 0.1em;
}

form{
    display: inline-block;
    text-align: right;
    width: 100%;
}

label{
    display: none;
}

input, textarea{
    width: 100%;
    min-width: 15px;
    margin-bottom: 0.5em;
}

input:last-child{
    width: 20%;
    font-family: "Smooch Sans", sans-serif;
    font-size: 100%;
    margin: 0;
}

input:placeholder-shown, textarea:placeholder-shown{
    font-family: "Smooch Sans", sans-serif;
    font-size: 100%;
}

.contactMe{
    text-align: left;
    margin: 2em 0 0 0;
}

.checkbox{
    display: flex;
    width: 100%;
    text-align: left;
    accent-color: hsl(209, 46%, 24%);
}

.checkbox p, .checkbox li, .checkbox input{
    display: inline;
    width: auto;
    margin: 0 0.1em;
}

.link{
    display: contents;
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s;
}

.link:hover{
    text-decoration: none;
    color: hsl(44, 97%, 56%);
}

iframe{
    width: 100%;
    height: 15em;
    border: 0;
}

.button{
    transition: 0.3s;
    border-radius: 8px;
    transition: all 0.1s ease-in-out;
    color: hsl(44, 97%, 56%);
    background-color: hsl(209, 46%, 24%);
}

.button:hover{
    filter: brightness(70%);
}

.button:active{
    background-color: hsl(120, 11%, 44%);
}
@media only screen and (min-width: 700px) {
    .contact{
        display: flex;
        justify-content: space-between;
    }

    .mailForm, .map{
        width: 45%;
    }

    form{
        display: block;
    }
}