/*/////// Profile picture & Square //////*/
.aboutMe{
    display: inline;
    margin: 5em;
}

.profilPicture{
    display: flex;
    position: absolute;
    z-index: 3;
    transition: transform .6s;
    padding-top: 1.5em;
}
  
.profilPicture:hover{
    transform: scale(1.7);
}

.imgsDiv{
    display: flex;
    position: relative;
    justify-content: center;
    height: 220px;
    width: 100%;
}

.square{
    display: flex;
    position: absolute;
    width: 150px;
    height: 150px;
}

#squareOne{
    z-index: 1;
    margin: -1em 0 0 -2em;
    background-color: hsl(35, 36%, 51%);
}

#squareTwo{
    z-index: 2;
    margin: 3em 0 0 2em;
    background-color: hsl(35, 66%, 81%);
}

.aboutMeText p{
    display: inline-block;
    width: 90%;
}

.aboutMeSecondDiv{
    background-color: hsl(35, 66%, 81%);
}

#squareThree{
    z-index: 1;
    margin: 1em 0 0 2em;
    background-color: hsl(35, 36%, 51%);
}

#squareFourth{
    z-index: 2;
    margin: 3em 0 0 -2em;
    background-color: hsl(0, 0%, 100%);
}
@media only screen and (min-width: 700px){
    .aboutMeFirstDiv, .aboutMeSecondDiv{
        display: flex;
        width: 80%;
        margin: auto;
    }

    #imgsDivTwo{
        order: 2;
    }

    #aboutMeTextTwo{
        order: 1;
    }
};