/*///////home site///////*/
.fontPrivat, .fontIndustry{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 300%;
    font-weight: bold;
    margin: 0;
    padding: 0 1em;
}

.navImgs{
    display: block;
}
  
.fontIndustry{
    cursor: pointer;
    color: hsl(290, 10%, 89%);
    -webkit-text-stroke: 1.5px hsl(0, 0%, 0%);
}

.fontPrivat{
    cursor: pointer;
    color: hsl(208, 40%, 9%);
    -webkit-text-stroke: 1.5px hsl(35, 36%, 51%);
}

section{
    position: relative;
}

.opacity {
    filter: opacity(.5);
}

.buttonIndustry, .buttonPrivat{
    width: 60%;
    min-width: 300px;
    height: auto;
    cursor: pointer;
    box-shadow: 0.1em 0.1em 0.2em hsl(0, 0%, 0%);
    opacity: 0.7;
    margin-top: 1em;
}

.buttonIndustry:hover, .buttonPrivat:hover{
    transition: all .2s ease;
    opacity: 0.8;
    box-shadow: 0.3em 0.3em 0.6em hsl(0, 0%, 0%);
}

.buttonIndustry:active, .buttonPrivat:active{
    opacity: 1;
}
/*//////// images gallery////////*/
.gallery{
    display: block;
    width: 80%;
    margin: auto;
}
  
.each-slide-effect{
    display: contents;
    position: relative;
    margin: auto;
    justify-content: center;
    background-size: cover;
    width: 80%;
    height: auto;
    float: none;
}

.each-slide-effect img{
    width: 100%;
    height: auto;
}

.react-slideshow-container .default-nav:last-of-type, .react-slideshow-container .default-nav:first-of-type{
    visibility: hidden;
}

.react-slideshow-container .default-nav svg{
    fill: hsla(0, 0%, 0%, 0.3);
}


.texte{
    display: contents;
    padding: 0 1.5em;
}
@media only screen and (min-width: 700px) {
    .navImgs{
        display: flex;
        justify-content: space-around;
    }

    .buttonIndustry, .buttonPrivat{
        width: 80%;
        margin-top: 2em;
    }

    .fontPrivat, .fontIndustry{
        font-size: 400%;
        padding: 0;
    }

    .fontPrivat{
        -webkit-text-stroke: 2.5px hsl(35, 36%, 51%);
    }

    .fontIndustry{
        -webkit-text-stroke: 2.5px hsl(0, 0%, 0%);
    }

    .react-slideshow-container .default-nav:last-of-type, .react-slideshow-container .default-nav:first-of-type{
        visibility: visible;
    }

    .each-slide-effect{
        display: grid;
        grid-template-columns: auto auto;
    }

    .each-slide-effect img{
        display: flex;
        width: 95%;
        margin: auto;
    }
    
    .texte{
        justify-content: end;
    }

    .info{
        display: block;
        width: 95%;
    }
}