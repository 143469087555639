header{
    text-align: center;
    background: hsl(120, 17%, 66%);
    min-height: 10em;
}
  
.languageChange{
    z-index: 2;
    display: block;
    position: absolute;
    margin: 0 0 0 1em;
    padding: 0;

}

.languages {
    position: relative;
}
    
.languages ul {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: all .5s ease;
    padding: 0;
}
  
.languages li:hover ul {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

    /*/////////// navigation /////////////*/
nav{
    display: flex;
    width: 0;
    position: fixed;
    z-index: 1;
    height: 100%;
    top: 0;
    right: 0;
    overflow-x: hidden;
    transition: 0.2s;
    padding-top: 1em;
    background-color: hsl(120, 21%, 88%);
}

nav ul li{
    display: block;
    font-size: 1.5em;
    padding:  0.2em 0.5em 0.2em 0.5em;
    border-radius: 8px;
    transition: 0.1s;
    color: hsl(209, 46%, 24%);
}

nav ul li:hover{
    cursor: pointer;
    color: hsl(44, 97%, 56%);
    background-color: hsl(209, 46%, 24%);
}

nav ul li:active{
    filter: brightness(70%);
}

nav ul li:first-child{
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0.2em;
    font-size: 3em;
    padding: 0 0.25em 0 0;
}

.navOpenBtn{
    float: right;
    cursor: pointer;
    padding: 0.5em 0.5em;



}
/*/////////// logo & slogen /////////////*/
.logo{
    width: 6em;
    height: auto;
    position: absolute;
    z-index: 1;
    transition: all 0.1s ease-in-out;
    margin: 1em 0 0 -2.3em;
}

.logo:hover{
    cursor: pointer;
    filter: brightness(80%);
}

.sloagen{
    display: block;
    position: absolute;
    font-family: "Comforter Brush", cursive;
    font-size: 200%;
    font-style: normal;
    color: hsl(209, 46%, 24%);
    width: 100%;
    margin-top: -1.5em;
}

.pageTheme{
    display: block;
    position: absolute;
    text-align: left;
    font-family: "Comforter Brush", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 290%;
    color: hsl(209, 46%, 24%);
    margin-top: -1.2em;
    padding-left: 0.5em;
}
/*/////////// footer /////////////*/
footer{
    display: block;
    text-align: center;
    width: 100%;
    background-color: hsl(120, 17%, 66%);
}

ul{
    list-style: none;
    padding: 0;
}

.socialMedia, .impressum{
    display: flex;
    margin: auto;
    justify-content: space-around;
    max-width: 60%;
    max-width: 20em;
}

.impressum li{
    border-radius: 8px;
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s;
    color: hsl(209, 46%, 24%);
    margin: 0 0 0.5em 0;
    padding: 0 0.25em;
}

.impressum li:hover{
    background-color: hsl(209, 46%, 24%);
    color: hsl(44, 97%, 56%);
    text-decoration: none;
}

.socialMedia svg{
    fill: hsl(209, 46%, 24%);
    transition: 0.3s;
    margin-top: 1em;
}

.socialMedia svg:hover{
    fill: hsl(44, 97%, 56%);
    text-decoration: none;
}
@media only screen and (min-width: 700px) {
    header{
        min-height: 11.5em;
    }

    .languageChange{
        margin-left: 0.5em;
    }

    nav{
        position: absolute;
        z-index: 1;
        background-color: hsla(0, 0%, 100%, 0);
        width: 100%;
        height: auto;
        margin-top: 2em;
        padding: 0;
    }

    nav ul{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .homeDesign{
        margin-right: 9em;
    }

    .navOpenBtn{
        display: none;
    }

    nav ul li:first-child{
        visibility: hidden;
    }

    .logo{
        position: absolute;

    }

    .sloagen{
        font-size: 300%;
        margin-top: -1.4em;
    }

    .pageTheme{
        margin-top: -1.4em;
    }
}

@media only screen and (min-width: 1080px) {
    header{
        width: 80%;
        margin: auto;
    }

    .languageChange{
        margin-left: 11%;
    }

    .sloagen, footer{
        width: 80%;
        margin: auto;
    }

    .sloagen{
        margin-top: -1.5em;
    }

    .pageTheme{
        margin-top: -1.4em;
    }
}
